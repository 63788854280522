<template>

    <b-card no-body class="border mt-1">
      <b-card-header  class="p-1">
          <b-card-title>Blocks</b-card-title>
      </b-card-header>
    <b-table
      :items="items"
      :fields="fields"
      responsive
      striped
      hover
       @row-clicked="getSlot"
       style="width: 100%;" fixed
    >
     <template #cell(result.blockhash)="data">
        <div class="w-100 text-truncate"   v-b-tooltip.hover.top="data.value"> {{data.value}}</div>
      </template>
      <template #cell(result.validator)="data">
        <div class="w-100 text-truncate"  v-b-tooltip.hover.top="data.value"> {{data.value}}</div>
      </template>
       <template #cell(currentSlot)="data">
        #{{data.value}}
      </template>
       <template #cell(result.blockTime)="data">
        {{convertToDate(data.value)}}
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { BTabs, BTab, BCardText,BRow,BCol,BTable,BCard,BCardTitle,BTableLite,VBTooltip,BCardHeader} from 'bootstrap-vue'
import GraphicsAnalitys from './GraphicsAnalitys.vue';
import Ripple from 'vue-ripple-directive'
export default {
  components: {
    BCardText,
    BTabs,
    BTab,BRow,BCol,BTable,BCard,BCardTitle,BTableLite,
    GraphicsAnalitys,BCardHeader
  },
  data(){
    
    return{
         fields: [{ key: 'result.blockhash', label: 'Block Hash',thStyle: {width: '20%'}}, 
         {key:'currentSlot',label:'Slot'},
         {key:'result.blockTime',label:'Time'},
         {key:'result.transactionCount',label:'Tx Count'},
         {key:'result.validator',label:'Leader',thStyle: {width: '20%'}},
         {key:'result.feeRewards',label:'Reward'}],
      items:[]

    }
  },
  directives: {
     'b-tooltip': VBTooltip,
    Ripple,
  },
  mounted:async function(){
   await this.getBlocks();
  },
  methods:{
        getBlocks:function() {
      const vm=this;
     const axios = require("axios");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios.get("https://public-api.solscan.io/block/last?limit=15",config) .then(function (response) {
      vm.items=response.data;
    })
    .catch((err) => {
      // handle error
      console.log(err);
    });
    },
      convertToDate:function(f){
const milliseconds = f * 1000
const dateObject = new Date(milliseconds)
const datex=new Date(Date.UTC(dateObject.getUTCFullYear(),dateObject.getUTCMonth(),dateObject.getUTCDate(),dateObject.getUTCHours(),dateObject.getMinutes(),dateObject.getUTCSeconds()))
const dateD=new Date(datex.toUTCString().substr(0, 25))
const humanDateFormat = dateD.toLocaleString() //2019-12-9 10:30:15
    return humanDateFormat;
    },
      getSlot:function(item){
       let  dataU={slot:item.currentSlot};
       localStorage.setItem('dataSlot',null);
       localStorage.removeItem('dataSlot'); 
       localStorage.setItem('dataSlot',JSON.stringify(dataU));
        this.$router.push({ name: 'blockdetails'})

    },
         numberWithCommas:function(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  }
}

</script>
<style>
</style>